@import "variables";

.mat-drawer-container {
    min-height: calc(100% - 64px);
}

#drawer {
    background: rgb(2, 100, 125);
    background: linear-gradient( 190deg, rgba(2, 100, 125, 1) 0%, rgba(14, 145, 179, 1) 84% );

    nav {
        padding-top: 32px;
    }
}

.mat-drawer-content {
    background-color: $color-grey;
    padding: 24px;
    padding-top: 12px;
}

.contianer_main_toolbar {
    background: rgb(2, 100, 125);
    background: linear-gradient( 90deg, rgba(2, 100, 125, 1) 0%, rgba(14, 145, 179, 1) 92% );

    .dx-texteditor-input {
        color: #fff;
    }
}
