.task-title {
    color: #07bbc2;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;

    p {
        margin-bottom: 4px;
    }

    @extend .text--wrap
}

.task-description {
    color: #656d78;
    font-size: 14px;
    font-weight: 300;
    margin-top: 8px;

    @extend .text--wrap
}

.task-date {
    color: #656d78;
    font-size: 12px;
    font-weight: 400;
    margin: 4px 0;
}

.task-button {
    margin: 8px 0 !important;
    padding: 0 !important;
    font-size: 20px;
    font-weight: 200;
    width: 100%;

    .mat-button-wrapper {
        vertical-align: middle;
    }

    .mat-icon {
        float: left;
        line-height: 38px;
        font-size: 24px;
        color: #fff;
        width: 38px;
        height: 38px;
    }
}

.task-button--orange {
    border: 1px solid #ffae01 !important;

    .mat-icon {
        background: linear-gradient(270deg, #ffae01 0%, #fb7b2e 100%);
    }
}

.task-button--red {
    border: 1px solid #fd3342 !important;

    .mat-icon {
        background: linear-gradient(270deg, #fd3342 0%, #bd392c 100%);
    }
}

.task-button--green {
    border: 1px solid #a8ca6b !important;

    .mat-icon {
        background: linear-gradient(90deg, #7fb224 0%, #a8ca6b 100%);
    }
}

.task-button--gray {
    border: 1px solid #656d78 !important;

    .mat-icon {
        background: linear-gradient(90deg, #44494e 0%, #656d78 100%);
    }
}
