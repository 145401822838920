@import "variables";

.support--tickets .dx-data-row.overdue {
    border-left: 3px solid red;
}

.support--tickets .dx-data-row.escalated {
    border-left: 3px solid purple;
}


.lead-stage {
    .dx-list {
        height: auto;
    }

    .dx-list-item-content {
        padding: 4px 6px;
    }
}

.stage_summary {
    border: 1px solid #bdc3c7;
    background-color: #fff;
    padding: 8px 16px;
}

    .stage_summary:not(.stage_summary_last) {
        border-right: none;
    }

.stage_summary_heading {
    color: #656d78;
    font-weight: 300;
    font-size: 18px;
    display: block;
}

.stage_summary_value {
    color: #656d78;
    font-weight: 400;
    font-size: 14px;
}

.stage_summary_count {
    color: #656d78;
    font-size: 14px;
    font-weight: 400;
}

.lead-box {
    border: 1px solid #bdc3c7;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    padding: 14px;
}

.lead-box-title {
    color: #07bbc2;
    font-size: 16px;
    font-weight: bold;

    @extend .text--wrap
}

.lead-box-text {
    color: #656d78;
    font-size: 14px;
}

.lead--stepper {
    .mat-horizontal-stepper-header-container {
        /*    background: #069545*/
    }

    .mat-horizontal-content-container {
        padding: 0;
    }

    .mat-step-header {
        background: #fff;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        pointer-events: none !important;
        min-width: 250px;
    }

        .mat-step-header[aria-label='active'] {
            .mat-step-label-active,
            .mat-step-label-active h4 {
                color: #fff !important;
            }

            @extend .bg--gradiant-green
        }

    mat-step-header[aria-label='skip'] {
        opacity: 0.3
    }

    .mat-step-icon {
        display: none;
    }

    .mat-stepper-horizontal-line {
        display: none;
    }
}
