@charset "UTF-8";
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.progress {
  height: progress-height;
  background: grey;
}

progress::-webkit-progress-bar {
  background: lightgrey;
}

progress.progress-green::-moz-progress-bar {
  height: progress-height;
  background: green;
}

progress.progress-green::-webkit-progress-value {
  height: progress-height;
  background: green;
}

progress.progress-red::-moz-progress-bar {
  height: progress-height;
  background: darkred;
}

progress.progress-red::-webkit-progress-value {
  height: progress-height;
  background: darkred;
}

.mat-drawer-container {
  min-height: calc(100% - 64px);
}

#drawer {
  background: #02647d;
  background: linear-gradient(190deg, #02647d 0%, #0e91b3 84%);
}
#drawer nav {
  padding-top: 32px;
}

.mat-drawer-content {
  background-color: #F7F7F7;
  padding: 24px;
  padding-top: 12px;
}

.contianer_main_toolbar {
  background: #02647d;
  background: linear-gradient(90deg, #02647d 0%, #0e91b3 92%);
}
.contianer_main_toolbar .dx-texteditor-input {
  color: #fff;
}

.modal-heading {
  border-bottom: 1px solid #ecf0f1;
  border-top: 4px solid #10a6ac;
  padding: 24px 0;
  margin-top: -24px;
  margin-bottom: 24px;
}

.mat-dialog-title {
  font-weight: 700;
  font-size: 24px;
  color: #656d78;
}

.modal-heading .mat-dialog-title {
  margin: 0;
}

.mat-dialog-content .mat-checkbox,
.mat-dialog-content mat-slide-toggle {
  display: block;
  margin: 12px 0;
}

.dx-mat-field {
  padding-bottom: 1.25em;
}
.dx-mat-field label {
  font-size: 11px;
}

.dx-button-mode-text.dx-button-default[aria-label=Save] {
  background-color: #07bbc2;
  color: #fff;
}
.dx-button-mode-text.dx-button-default[aria-label=Save] .dx-state-hover {
  background-color: #07bbc2;
}

/*.dx-texteditor.dx-editor-underlined .dx-texteditor-input {
  padding-top: 5px;
}

.dx-texteditor-input,
.dx-placeholder {
  font-size: 14px;
}

.dx-placeholder {
  line-height: 0;
}*/
.dx-datagrid-headers .dx-header-row {
  background-color: #656d78;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort-indicator,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content {
  color: #e0e0e0;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 50px;
}

.dx-list-search {
  width: 50%;
}

.dx-list .dx-list-item,
.dx-list-item-content {
  white-space: normal;
}

.dx-popup-normal {
  border-top-color: #07bbc2;
  border-top-style: solid;
  border-top-width: thick;
}

.dx-filemanager .dx-filemanager-toolbar {
  min-height: auto;
}

.dx-dashboard-control {
  background-color: initial;
}

.dx-dashboard-viewer-content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dx-dashboard-title-toolbar .dx-toolbar-item,
.dx-dashboard-caption-toolbar .dx-toolbar-item {
  color: #7f8c8d;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: bold;
}

/*.dx-dashboard-item-container {
  border-radius: 0;
  box-shadow: none;

  @extend .border--solid, .border--width-1, .border--greenish-light
}
*/
.task-title {
  color: #07bbc2;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}
.task-title p {
  margin-bottom: 4px;
}

.task-description {
  color: #656d78;
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
}

.task-date {
  color: #656d78;
  font-size: 12px;
  font-weight: 400;
  margin: 4px 0;
}

.task-button {
  margin: 8px 0 !important;
  padding: 0 !important;
  font-size: 20px;
  font-weight: 200;
  width: 100%;
}
.task-button .mat-button-wrapper {
  vertical-align: middle;
}
.task-button .mat-icon {
  float: left;
  line-height: 38px;
  font-size: 24px;
  color: #fff;
  width: 38px;
  height: 38px;
}

.task-button--orange {
  border: 1px solid #ffae01 !important;
}
.task-button--orange .mat-icon {
  background: linear-gradient(270deg, #ffae01 0%, #fb7b2e 100%);
}

.task-button--red {
  border: 1px solid #fd3342 !important;
}
.task-button--red .mat-icon {
  background: linear-gradient(270deg, #fd3342 0%, #bd392c 100%);
}

.task-button--green {
  border: 1px solid #a8ca6b !important;
}
.task-button--green .mat-icon {
  background: linear-gradient(90deg, #7fb224 0%, #a8ca6b 100%);
}

.task-button--gray {
  border: 1px solid #656d78 !important;
}
.task-button--gray .mat-icon {
  background: linear-gradient(90deg, #44494e 0%, #656d78 100%);
}

.support--tickets .dx-data-row.overdue {
  border-left: 3px solid red;
}

.support--tickets .dx-data-row.escalated {
  border-left: 3px solid purple;
}

.lead-stage .dx-list {
  height: auto;
}
.lead-stage .dx-list-item-content {
  padding: 4px 6px;
}

.stage_summary {
  border: 1px solid #bdc3c7;
  background-color: #fff;
  padding: 8px 16px;
}

.stage_summary:not(.stage_summary_last) {
  border-right: none;
}

.stage_summary_heading {
  color: #656d78;
  font-weight: 300;
  font-size: 18px;
  display: block;
}

.stage_summary_value {
  color: #656d78;
  font-weight: 400;
  font-size: 14px;
}

.stage_summary_count {
  color: #656d78;
  font-size: 14px;
  font-weight: 400;
}

.lead-box {
  border: 1px solid #bdc3c7;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  padding: 14px;
}

.lead-box-title {
  color: #07bbc2;
  font-size: 16px;
  font-weight: bold;
}

.lead-box-text {
  color: #656d78;
  font-size: 14px;
}

.lead--stepper .mat-horizontal-stepper-header-container {
  /*    background: #069545*/
}
.lead--stepper .mat-horizontal-content-container {
  padding: 0;
}
.lead--stepper .mat-step-header {
  background: #fff;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  pointer-events: none !important;
  min-width: 250px;
}
.lead--stepper .mat-step-header[aria-label=active] .mat-step-label-active,
.lead--stepper .mat-step-header[aria-label=active] .mat-step-label-active h4 {
  color: #fff !important;
}
.lead--stepper mat-step-header[aria-label=skip] {
  opacity: 0.3;
}
.lead--stepper .mat-step-icon {
  display: none;
}
.lead--stepper .mat-stepper-horizontal-line {
  display: none;
}

.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 40em) {
  .grid-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.grid-container.fluid {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 40em) {
  .grid-container.fluid {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.cell {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
}
.cell.auto {
  flex: 1 1 0;
}
.cell.shrink {
  flex: 0 0 auto;
}

.grid-x > .auto {
  width: auto;
}
.grid-x > .shrink {
  width: auto;
}

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto;
  }
}
.grid-x > .small-12, .grid-x > .small-11, .grid-x > .small-10, .grid-x > .small-9, .grid-x > .small-8, .grid-x > .small-7, .grid-x > .small-6, .grid-x > .small-5, .grid-x > .small-4, .grid-x > .small-3, .grid-x > .small-2, .grid-x > .small-1 {
  flex: 0 0 auto;
}

.grid-x > .small-1 {
  width: 8.3333333333%;
}

.grid-x > .small-2 {
  width: 16.6666666667%;
}

.grid-x > .small-3 {
  width: 25%;
}

.grid-x > .small-4 {
  width: 33.3333333333%;
}

.grid-x > .small-5 {
  width: 41.6666666667%;
}

.grid-x > .small-6 {
  width: 50%;
}

.grid-x > .small-7 {
  width: 58.3333333333%;
}

.grid-x > .small-8 {
  width: 66.6666666667%;
}

.grid-x > .small-9 {
  width: 75%;
}

.grid-x > .small-10 {
  width: 83.3333333333%;
}

.grid-x > .small-11 {
  width: 91.6666666667%;
}

.grid-x > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0;
    width: auto;
  }

  .grid-x > .medium-12, .grid-x > .medium-11, .grid-x > .medium-10, .grid-x > .medium-9, .grid-x > .medium-8, .grid-x > .medium-7, .grid-x > .medium-6, .grid-x > .medium-5, .grid-x > .medium-4, .grid-x > .medium-3, .grid-x > .medium-2, .grid-x > .medium-1, .grid-x > .medium-shrink {
    flex: 0 0 auto;
  }

  .grid-x > .medium-shrink {
    width: auto;
  }

  .grid-x > .medium-1 {
    width: 8.3333333333%;
  }

  .grid-x > .medium-2 {
    width: 16.6666666667%;
  }

  .grid-x > .medium-3 {
    width: 25%;
  }

  .grid-x > .medium-4 {
    width: 33.3333333333%;
  }

  .grid-x > .medium-5 {
    width: 41.6666666667%;
  }

  .grid-x > .medium-6 {
    width: 50%;
  }

  .grid-x > .medium-7 {
    width: 58.3333333333%;
  }

  .grid-x > .medium-8 {
    width: 66.6666666667%;
  }

  .grid-x > .medium-9 {
    width: 75%;
  }

  .grid-x > .medium-10 {
    width: 83.3333333333%;
  }

  .grid-x > .medium-11 {
    width: 91.6666666667%;
  }

  .grid-x > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0;
    width: auto;
  }

  .grid-x > .large-12, .grid-x > .large-11, .grid-x > .large-10, .grid-x > .large-9, .grid-x > .large-8, .grid-x > .large-7, .grid-x > .large-6, .grid-x > .large-5, .grid-x > .large-4, .grid-x > .large-3, .grid-x > .large-2, .grid-x > .large-1, .grid-x > .large-shrink {
    flex: 0 0 auto;
  }

  .grid-x > .large-shrink {
    width: auto;
  }

  .grid-x > .large-1 {
    width: 8.3333333333%;
  }

  .grid-x > .large-2 {
    width: 16.6666666667%;
  }

  .grid-x > .large-3 {
    width: 25%;
  }

  .grid-x > .large-4 {
    width: 33.3333333333%;
  }

  .grid-x > .large-5 {
    width: 41.6666666667%;
  }

  .grid-x > .large-6 {
    width: 50%;
  }

  .grid-x > .large-7 {
    width: 58.3333333333%;
  }

  .grid-x > .large-8 {
    width: 66.6666666667%;
  }

  .grid-x > .large-9 {
    width: 75%;
  }

  .grid-x > .large-10 {
    width: 83.3333333333%;
  }

  .grid-x > .large-11 {
    width: 91.6666666667%;
  }

  .grid-x > .large-12 {
    width: 100%;
  }
}
.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-x {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
}
.grid-margin-x > .cell {
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-x > .cell {
    width: calc(100% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
}
.grid-margin-x > .auto {
  width: auto;
}
.grid-margin-x > .shrink {
  width: auto;
}
.grid-margin-x > .small-1 {
  width: calc(8.3333333333% - 1.25rem);
}
.grid-margin-x > .small-2 {
  width: calc(16.6666666667% - 1.25rem);
}
.grid-margin-x > .small-3 {
  width: calc(25% - 1.25rem);
}
.grid-margin-x > .small-4 {
  width: calc(33.3333333333% - 1.25rem);
}
.grid-margin-x > .small-5 {
  width: calc(41.6666666667% - 1.25rem);
}
.grid-margin-x > .small-6 {
  width: calc(50% - 1.25rem);
}
.grid-margin-x > .small-7 {
  width: calc(58.3333333333% - 1.25rem);
}
.grid-margin-x > .small-8 {
  width: calc(66.6666666667% - 1.25rem);
}
.grid-margin-x > .small-9 {
  width: calc(75% - 1.25rem);
}
.grid-margin-x > .small-10 {
  width: calc(83.3333333333% - 1.25rem);
}
.grid-margin-x > .small-11 {
  width: calc(91.6666666667% - 1.25rem);
}
.grid-margin-x > .small-12 {
  width: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .small-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .small-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .small-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x > .medium-auto {
    width: auto;
  }
  .grid-margin-x > .medium-shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .medium-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .medium-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .medium-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-12 {
    width: calc(100% - 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-x > .large-auto {
    width: auto;
  }
  .grid-margin-x > .large-shrink {
    width: auto;
  }
  .grid-margin-x > .large-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .large-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .large-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .large-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-12 {
    width: calc(100% - 1.875rem);
  }
}

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-padding-x > .cell {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.small-up-1 > .cell {
  width: 100%;
}

.small-up-2 > .cell {
  width: 50%;
}

.small-up-3 > .cell {
  width: 33.3333333333%;
}

.small-up-4 > .cell {
  width: 25%;
}

.small-up-5 > .cell {
  width: 20%;
}

.small-up-6 > .cell {
  width: 16.6666666667%;
}

.small-up-7 > .cell {
  width: 14.2857142857%;
}

.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%;
  }

  .medium-up-2 > .cell {
    width: 50%;
  }

  .medium-up-3 > .cell {
    width: 33.3333333333%;
  }

  .medium-up-4 > .cell {
    width: 25%;
  }

  .medium-up-5 > .cell {
    width: 20%;
  }

  .medium-up-6 > .cell {
    width: 16.6666666667%;
  }

  .medium-up-7 > .cell {
    width: 14.2857142857%;
  }

  .medium-up-8 > .cell {
    width: 12.5%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%;
  }

  .large-up-2 > .cell {
    width: 50%;
  }

  .large-up-3 > .cell {
    width: 33.3333333333%;
  }

  .large-up-4 > .cell {
    width: 25%;
  }

  .large-up-5 > .cell {
    width: 20%;
  }

  .large-up-6 > .cell {
    width: 16.6666666667%;
  }

  .large-up-7 > .cell {
    width: 14.2857142857%;
  }

  .large-up-8 > .cell {
    width: 12.5%;
  }
}
.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem);
}

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem);
}

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.3333333333% - 1.25rem);
}

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem);
}

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem);
}

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.6666666667% - 1.25rem);
}

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.2857142857% - 1.25rem);
}

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }

  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }

  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }

  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }

  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }

  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }

  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }

  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }

  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }

  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }

  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}
.small-margin-collapse {
  margin-right: 0;
  margin-left: 0;
}
.small-margin-collapse > .cell {
  margin-right: 0;
  margin-left: 0;
}
.small-margin-collapse > .small-1 {
  width: 8.3333333333%;
}
.small-margin-collapse > .small-2 {
  width: 16.6666666667%;
}
.small-margin-collapse > .small-3 {
  width: 25%;
}
.small-margin-collapse > .small-4 {
  width: 33.3333333333%;
}
.small-margin-collapse > .small-5 {
  width: 41.6666666667%;
}
.small-margin-collapse > .small-6 {
  width: 50%;
}
.small-margin-collapse > .small-7 {
  width: 58.3333333333%;
}
.small-margin-collapse > .small-8 {
  width: 66.6666666667%;
}
.small-margin-collapse > .small-9 {
  width: 75%;
}
.small-margin-collapse > .small-10 {
  width: 83.3333333333%;
}
.small-margin-collapse > .small-11 {
  width: 91.6666666667%;
}
.small-margin-collapse > .small-12 {
  width: 100%;
}
@media print, screen and (min-width: 40em) {
  .small-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .small-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .small-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .small-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .small-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .small-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .small-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .small-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .small-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .small-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .small-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .small-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .small-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .small-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .small-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .small-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}
.small-padding-collapse > .cell {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .small-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .small-3 {
    width: 25%;
  }
  .medium-margin-collapse > .small-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .small-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .small-6 {
    width: 50%;
  }
  .medium-margin-collapse > .small-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .small-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .small-9 {
    width: 75%;
  }
  .medium-margin-collapse > .small-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .small-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .small-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }
  .medium-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }
  .medium-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }
  .medium-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .large-3 {
    width: 25%;
  }
  .medium-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .large-6 {
    width: 50%;
  }
  .medium-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .large-9 {
    width: 75%;
  }
  .medium-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .small-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .small-3 {
    width: 25%;
  }
  .large-margin-collapse > .small-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .small-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .small-6 {
    width: 50%;
  }
  .large-margin-collapse > .small-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .small-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .small-9 {
    width: 75%;
  }
  .large-margin-collapse > .small-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .small-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .small-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .medium-3 {
    width: 25%;
  }
  .large-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .medium-6 {
    width: 50%;
  }
  .large-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .medium-9 {
    width: 75%;
  }
  .large-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .large-3 {
    width: 25%;
  }
  .large-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .large-6 {
    width: 50%;
  }
  .large-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .large-9 {
    width: 75%;
  }
  .large-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 1.25rem / 2);
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.3333333333% + 1.25rem / 2);
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.6666666667% + 1.25rem / 2);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 1.25rem / 2);
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.3333333333% + 1.25rem / 2);
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.6666666667% + 1.25rem / 2);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 1.25rem / 2);
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.3333333333% + 1.25rem / 2);
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.6666666667% + 1.25rem / 2);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 1.25rem / 2);
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.3333333333% + 1.25rem / 2);
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.6666666667% + 1.25rem / 2);
}

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%;
  }

  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1.875rem / 2);
  }

  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }

  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.3333333333% + 1.875rem / 2);
  }

  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }

  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.6666666667% + 1.875rem / 2);
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1.875rem / 2);
  }

  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }

  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.3333333333% + 1.875rem / 2);
  }

  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }

  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.6666666667% + 1.875rem / 2);
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1.875rem / 2);
  }

  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }

  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.3333333333% + 1.875rem / 2);
  }

  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }

  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.6666666667% + 1.875rem / 2);
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1.875rem / 2);
  }

  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }

  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.3333333333% + 1.875rem / 2);
  }

  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }

  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.6666666667% + 1.875rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%;
  }

  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 1.875rem / 2);
  }

  .large-offset-1 {
    margin-left: 8.3333333333%;
  }

  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.3333333333% + 1.875rem / 2);
  }

  .large-offset-2 {
    margin-left: 16.6666666667%;
  }

  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.6666666667% + 1.875rem / 2);
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 1.875rem / 2);
  }

  .large-offset-4 {
    margin-left: 33.3333333333%;
  }

  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.3333333333% + 1.875rem / 2);
  }

  .large-offset-5 {
    margin-left: 41.6666666667%;
  }

  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.6666666667% + 1.875rem / 2);
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 1.875rem / 2);
  }

  .large-offset-7 {
    margin-left: 58.3333333333%;
  }

  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.3333333333% + 1.875rem / 2);
  }

  .large-offset-8 {
    margin-left: 66.6666666667%;
  }

  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.6666666667% + 1.875rem / 2);
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 1.875rem / 2);
  }

  .large-offset-10 {
    margin-left: 83.3333333333%;
  }

  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.3333333333% + 1.875rem / 2);
  }

  .large-offset-11 {
    margin-left: 91.6666666667%;
  }

  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.6666666667% + 1.875rem / 2);
  }
}
.grid-y {
  display: flex;
  flex-flow: column nowrap;
}
.grid-y > .cell {
  height: auto;
  max-height: none;
}
.grid-y > .auto {
  height: auto;
}
.grid-y > .shrink {
  height: auto;
}
.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  flex-basis: auto;
}
@media print, screen and (min-width: 40em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex-basis: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex-basis: auto;
  }
}
.grid-y > .small-12, .grid-y > .small-11, .grid-y > .small-10, .grid-y > .small-9, .grid-y > .small-8, .grid-y > .small-7, .grid-y > .small-6, .grid-y > .small-5, .grid-y > .small-4, .grid-y > .small-3, .grid-y > .small-2, .grid-y > .small-1 {
  flex: 0 0 auto;
}
.grid-y > .small-1 {
  height: 8.3333333333%;
}
.grid-y > .small-2 {
  height: 16.6666666667%;
}
.grid-y > .small-3 {
  height: 25%;
}
.grid-y > .small-4 {
  height: 33.3333333333%;
}
.grid-y > .small-5 {
  height: 41.6666666667%;
}
.grid-y > .small-6 {
  height: 50%;
}
.grid-y > .small-7 {
  height: 58.3333333333%;
}
.grid-y > .small-8 {
  height: 66.6666666667%;
}
.grid-y > .small-9 {
  height: 75%;
}
.grid-y > .small-10 {
  height: 83.3333333333%;
}
.grid-y > .small-11 {
  height: 91.6666666667%;
}
.grid-y > .small-12 {
  height: 100%;
}
@media print, screen and (min-width: 40em) {
  .grid-y > .medium-auto {
    flex: 1 1 0;
    height: auto;
  }
  .grid-y > .medium-12, .grid-y > .medium-11, .grid-y > .medium-10, .grid-y > .medium-9, .grid-y > .medium-8, .grid-y > .medium-7, .grid-y > .medium-6, .grid-y > .medium-5, .grid-y > .medium-4, .grid-y > .medium-3, .grid-y > .medium-2, .grid-y > .medium-1, .grid-y > .medium-shrink {
    flex: 0 0 auto;
  }
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.3333333333%;
  }
  .grid-y > .medium-2 {
    height: 16.6666666667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.3333333333%;
  }
  .grid-y > .medium-5 {
    height: 41.6666666667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.3333333333%;
  }
  .grid-y > .medium-8 {
    height: 66.6666666667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.3333333333%;
  }
  .grid-y > .medium-11 {
    height: 91.6666666667%;
  }
  .grid-y > .medium-12 {
    height: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y > .large-auto {
    flex: 1 1 0;
    height: auto;
  }
  .grid-y > .large-12, .grid-y > .large-11, .grid-y > .large-10, .grid-y > .large-9, .grid-y > .large-8, .grid-y > .large-7, .grid-y > .large-6, .grid-y > .large-5, .grid-y > .large-4, .grid-y > .large-3, .grid-y > .large-2, .grid-y > .large-1, .grid-y > .large-shrink {
    flex: 0 0 auto;
  }
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.3333333333%;
  }
  .grid-y > .large-2 {
    height: 16.6666666667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.3333333333%;
  }
  .grid-y > .large-5 {
    height: 41.6666666667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.3333333333%;
  }
  .grid-y > .large-8 {
    height: 66.6666666667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.3333333333%;
  }
  .grid-y > .large-11 {
    height: 91.6666666667%;
  }
  .grid-y > .large-12 {
    height: 100%;
  }
}

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}
.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-padding-y > .cell {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
}

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}
.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}
.grid-margin-y > .auto {
  height: auto;
}
.grid-margin-y > .shrink {
  height: auto;
}
.grid-margin-y > .small-1 {
  height: calc(8.3333333333% - 1.25rem);
}
.grid-margin-y > .small-2 {
  height: calc(16.6666666667% - 1.25rem);
}
.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}
.grid-margin-y > .small-4 {
  height: calc(33.3333333333% - 1.25rem);
}
.grid-margin-y > .small-5 {
  height: calc(41.6666666667% - 1.25rem);
}
.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}
.grid-margin-y > .small-7 {
  height: calc(58.3333333333% - 1.25rem);
}
.grid-margin-y > .small-8 {
  height: calc(66.6666666667% - 1.25rem);
}
.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}
.grid-margin-y > .small-10 {
  height: calc(83.3333333333% - 1.25rem);
}
.grid-margin-y > .small-11 {
  height: calc(91.6666666667% - 1.25rem);
}
.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.cell-block-container > .grid-x {
  max-height: 100%;
  flex-wrap: nowrap;
}

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }

  .cell .medium-grid-frame {
    width: 100%;
  }

  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }

  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }

  .cell .large-grid-frame {
    width: 100%;
  }

  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }

  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
.grid-y.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
  width: auto;
}
@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
    width: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
    width: auto;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}
@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}
.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}
.grid-margin-y > .auto {
  height: auto;
}
.grid-margin-y > .shrink {
  height: auto;
}
.grid-margin-y > .small-1 {
  height: calc(8.3333333333% - 1.25rem);
}
.grid-margin-y > .small-2 {
  height: calc(16.6666666667% - 1.25rem);
}
.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}
.grid-margin-y > .small-4 {
  height: calc(33.3333333333% - 1.25rem);
}
.grid-margin-y > .small-5 {
  height: calc(41.6666666667% - 1.25rem);
}
.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}
.grid-margin-y > .small-7 {
  height: calc(58.3333333333% - 1.25rem);
}
.grid-margin-y > .small-8 {
  height: calc(66.6666666667% - 1.25rem);
}
.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}
.grid-margin-y > .small-10 {
  height: calc(83.3333333333% - 1.25rem);
}
.grid-margin-y > .small-11 {
  height: calc(91.6666666667% - 1.25rem);
}
.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem);
}
@media print, screen and (min-width: 40em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}
.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
[data-whatinput=mouse] .menu li {
  outline: 0;
}
.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}
.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}
.menu input {
  display: inline-block;
}
.menu, .menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}
.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}
.menu.vertical.icon-top li a img,
.menu.vertical.icon-top li a i,
.menu.vertical.icon-top li a svg, .menu.vertical.icon-bottom li a img,
.menu.vertical.icon-bottom li a i,
.menu.vertical.icon-bottom li a svg {
  text-align: left;
}
.menu.expanded li {
  flex: 1 1 0px;
}
.menu.expanded.icon-top li a img,
.menu.expanded.icon-top li a i,
.menu.expanded.icon-top li a svg, .menu.expanded.icon-bottom li a img,
.menu.expanded.icon-bottom li a i,
.menu.expanded.icon-bottom li a svg {
  text-align: left;
}
.menu.simple {
  align-items: center;
}
.menu.simple li + li {
  margin-left: 1rem;
}
.menu.simple a {
  padding: 0;
}
@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.medium-expanded li {
    flex: 1 1 0px;
  }
  .menu.medium-simple li {
    flex: 1 1 0px;
  }
}
@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.large-expanded li {
    flex: 1 1 0px;
  }
  .menu.large-simple li {
    flex: 1 1 0px;
  }
}
.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}
.menu.icons a {
  display: flex;
}
.menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
  display: flex;
}
.menu.icon-left li a, .menu.nested.icon-left li a {
  flex-flow: row nowrap;
}
.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg, .menu.nested.icon-left li a img,
.menu.nested.icon-left li a i,
.menu.nested.icon-left li a svg {
  margin-right: 0.25rem;
}
.menu.icon-right li a, .menu.nested.icon-right li a {
  flex-flow: row nowrap;
}
.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg, .menu.nested.icon-right li a img,
.menu.nested.icon-right li a i,
.menu.nested.icon-right li a svg {
  margin-left: 0.25rem;
}
.menu.icon-top li a, .menu.nested.icon-top li a {
  flex-flow: column nowrap;
}
.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg, .menu.nested.icon-top li a img,
.menu.nested.icon-top li a i,
.menu.nested.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu.icon-bottom li a, .menu.nested.icon-bottom li a {
  flex-flow: column nowrap;
}
.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg, .menu.nested.icon-bottom li a img,
.menu.nested.icon-bottom li a i,
.menu.nested.icon-bottom li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu .is-active > a {
  background: #1779ba;
  color: #fefefe;
}
.menu .active > a {
  background: #1779ba;
  color: #fefefe;
}
.menu.align-left {
  justify-content: flex-start;
}
.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}
.menu.align-right li .submenu li {
  justify-content: flex-start;
}
.menu.align-right.vertical li {
  display: block;
  text-align: right;
}
.menu.align-right.vertical li .submenu li {
  text-align: right;
}
.menu.align-right.icon-top li a img,
.menu.align-right.icon-top li a i,
.menu.align-right.icon-top li a svg, .menu.align-right.icon-bottom li a img,
.menu.align-right.icon-bottom li a i,
.menu.align-right.icon-bottom li a svg {
  text-align: right;
}
.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}
.menu.align-center li {
  display: flex;
  justify-content: center;
}
.menu.align-center li .submenu li {
  justify-content: flex-start;
}
.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered > .menu {
  justify-content: center;
}
.menu-centered > .menu li {
  display: flex;
  justify-content: center;
}
.menu-centered > .menu li .submenu li {
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none;
}
.breadcrumbs::before, .breadcrumbs::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.breadcrumbs::after {
  clear: both;
}
.breadcrumbs li {
  float: left;
  font-size: 0.6875rem;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase;
}
.breadcrumbs li:not(:last-child)::after {
  position: relative;
  margin: 0 0.75rem;
  opacity: 1;
  content: "/";
  color: #cacaca;
}
.breadcrumbs a {
  color: #1779ba;
}
.breadcrumbs a:hover {
  text-decoration: underline;
}
.breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.clearfix::after {
  clear: both;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

@media print, screen and (max-width: 39.99875em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .show-for-medium {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .show-for-large {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}
@media screen and (orientation: landscape) {
  .show-for-landscape,
.hide-for-portrait {
    display: block !important;
  }
}
@media screen and (orientation: portrait) {
  .show-for-landscape,
.hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}
@media screen and (orientation: landscape) {
  .hide-for-landscape,
.show-for-portrait {
    display: none !important;
  }
}
@media screen and (orientation: portrait) {
  .hide-for-landscape,
.show-for-portrait {
    display: block !important;
  }
}

.show-for-dark-mode {
  display: none;
}

.hide-for-dark-mode {
  display: block;
}

@media screen and (prefers-color-scheme: dark) {
  .show-for-dark-mode {
    display: block !important;
  }

  .hide-for-dark-mode {
    display: none !important;
  }
}
.show-for-ie {
  display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .show-for-ie {
    display: block !important;
  }

  .hide-for-ie {
    display: none !important;
  }
}
.show-for-sticky {
  display: none;
}

.is-stuck .show-for-sticky {
  display: block;
}

.is-stuck .hide-for-sticky {
  display: none;
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-left.vertical.menu > li > a {
  justify-content: flex-start;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }

  .medium-order-2 {
    order: 2;
  }

  .medium-order-3 {
    order: 3;
  }

  .medium-order-4 {
    order: 4;
  }

  .medium-order-5 {
    order: 5;
  }

  .medium-order-6 {
    order: 6;
  }
}
@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }

  .large-order-2 {
    order: 2;
  }

  .large-order-3 {
    order: 3;
  }

  .large-order-4 {
    order: 4;
  }

  .large-order-5 {
    order: 5;
  }

  .large-order-6 {
    order: 6;
  }
}
.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex;
  }

  .medium-flex-child-auto {
    flex: 1 1 auto;
  }

  .medium-flex-child-grow {
    flex: 1 0 auto;
  }

  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }

  .medium-flex-dir-row {
    flex-direction: row;
  }

  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }

  .medium-flex-dir-column {
    flex-direction: column;
  }

  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }

  .large-flex-child-auto {
    flex: 1 1 auto;
  }

  .large-flex-child-grow {
    flex: 1 0 auto;
  }

  .large-flex-child-shrink {
    flex: 0 1 auto;
  }

  .large-flex-dir-row {
    flex-direction: row;
  }

  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }

  .large-flex-dir-column {
    flex-direction: column;
  }

  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
.text-hide {
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important;
}

.text-truncate {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-wrap {
  word-wrap: break-word !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-overline {
  text-decoration: overline !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.font-wide {
  letter-spacing: 0.25rem;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic !important;
}

ul.list-disc {
  list-style-type: disc !important;
}

ul.list-circle {
  list-style-type: circle !important;
}

ul.list-square {
  list-style-type: square !important;
}

ol.list-decimal {
  list-style-type: decimal !important;
}

ol.list-lower-alpha {
  list-style-type: lower-alpha !important;
}

ol.list-lower-latin {
  list-style-type: lower-latin !important;
}

ol.list-lower-roman {
  list-style-type: lower-roman !important;
}

ol.list-upper-alpha {
  list-style-type: upper-alpha !important;
}

ol.list-upper-latin {
  list-style-type: upper-latin !important;
}

ol.list-upper-roman {
  list-style-type: upper-roman !important;
}

.rounded {
  border-radius: 5000px !important;
}
.rounded .switch-paddle {
  border-radius: 5000px !important;
}
.rounded .switch-paddle:after {
  border-radius: 50%;
}

.radius {
  border-radius: 0.1875rem;
}

.bordered {
  border: 0.0625rem solid #cacaca;
}

.shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4375rem;
  content: "";
  border-bottom-width: 0;
  border-color: #0a0a0a transparent transparent;
}

.arrow-up {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4375rem;
  content: "";
  border-top-width: 0;
  border-color: transparent transparent #0a0a0a;
}

.arrow-right {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4375rem;
  content: "";
  border-right-width: 0;
  border-color: transparent transparent transparent #0a0a0a;
}

.arrow-left {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4375rem;
  content: "";
  border-left-width: 0;
  border-color: transparent #0a0a0a transparent transparent;
}

.separator-center {
  text-align: center !important;
}
.separator-center::before, .separator-center::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.separator-center::after {
  clear: both;
}
.separator-center::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.125rem solid #1779ba;
  margin: 1rem auto 0;
}

.separator-left {
  text-align: left !important;
}
.separator-left::before, .separator-left::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.separator-left::after {
  clear: both;
}
.separator-left::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.125rem solid #1779ba;
  margin: 1rem auto 0;
  margin-left: 0 !important;
}

.separator-right {
  text-align: right !important;
}
.separator-right::before, .separator-right::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.separator-right::after {
  clear: both;
}
.separator-right::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.125rem solid #1779ba;
  margin: 1rem auto 0;
  margin-right: 0 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
  -webkit-overflow-scrolling: touch;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.display-table {
  display: table !important;
}

.display-table-cell {
  display: table-cell !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-fixed-top {
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 975;
}

.position-fixed-bottom {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 975;
}

.border-box {
  box-sizing: border-box !important;
}

.border-none {
  border: 0 !important;
}

.width-25 {
  width: 25% !important;
}

.width-50 {
  width: 50% !important;
}

.width-75 {
  width: 75% !important;
}

.width-100 {
  width: 100% !important;
}

.height-25 {
  height: 25% !important;
}

.height-50 {
  height: 50% !important;
}

.height-75 {
  height: 75% !important;
}

.height-100 {
  height: 100% !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.max-height-100 {
  max-height: 100% !important;
}

.margin-0 {
  margin: 0rem !important;
}

.margin-top-0 {
  margin-top: 0rem !important;
}

.margin-right-0 {
  margin-right: 0rem !important;
}

.margin-bottom-0 {
  margin-bottom: 0rem !important;
}

.margin-left-0 {
  margin-left: 0rem !important;
}

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.padding-0 {
  padding: 0rem !important;
}

.padding-top-0 {
  padding-top: 0rem !important;
}

.padding-right-0 {
  padding-right: 0rem !important;
}

.padding-bottom-0 {
  padding-bottom: 0rem !important;
}

.padding-left-0 {
  padding-left: 0rem !important;
}

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.margin-1 {
  margin: 1rem !important;
}

.margin-top-1 {
  margin-top: 1rem !important;
}

.margin-right-1 {
  margin-right: 1rem !important;
}

.margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.margin-left-1 {
  margin-left: 1rem !important;
}

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.padding-1 {
  padding: 1rem !important;
}

.padding-top-1 {
  padding-top: 1rem !important;
}

.padding-right-1 {
  padding-right: 1rem !important;
}

.padding-bottom-1 {
  padding-bottom: 1rem !important;
}

.padding-left-1 {
  padding-left: 1rem !important;
}

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.margin-2 {
  margin: 2rem !important;
}

.margin-top-2 {
  margin-top: 2rem !important;
}

.margin-right-2 {
  margin-right: 2rem !important;
}

.margin-bottom-2 {
  margin-bottom: 2rem !important;
}

.margin-left-2 {
  margin-left: 2rem !important;
}

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.padding-2 {
  padding: 2rem !important;
}

.padding-top-2 {
  padding-top: 2rem !important;
}

.padding-right-2 {
  padding-right: 2rem !important;
}

.padding-bottom-2 {
  padding-bottom: 2rem !important;
}

.padding-left-2 {
  padding-left: 2rem !important;
}

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.margin-3 {
  margin: 3rem !important;
}

.margin-top-3 {
  margin-top: 3rem !important;
}

.margin-right-3 {
  margin-right: 3rem !important;
}

.margin-bottom-3 {
  margin-bottom: 3rem !important;
}

.margin-left-3 {
  margin-left: 3rem !important;
}

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.padding-3 {
  padding: 3rem !important;
}

.padding-top-3 {
  padding-top: 3rem !important;
}

.padding-right-3 {
  padding-right: 3rem !important;
}

.padding-bottom-3 {
  padding-bottom: 3rem !important;
}

.padding-left-3 {
  padding-left: 3rem !important;
}

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}
h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #cacaca;
}

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem;
  }

  h2, .h2 {
    font-size: 2.5rem;
  }

  h3, .h3 {
    font-size: 1.9375rem;
  }

  h4, .h4 {
    font-size: 1.5625rem;
  }

  h5, .h5 {
    font-size: 1.25rem;
  }

  h6, .h6 {
    font-size: 1rem;
  }
}
a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer;
}
a:hover, a:focus {
  color: #1468a0;
}
a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}
blockquote, blockquote p {
  line-height: 1.6;
  color: #8a8a8a;
}

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}
p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.cite-block, cite {
  display: block;
  color: #8a8a8a;
  font-size: 0.8125rem;
}
.cite-block:before, cite:before {
  content: "— ";
}

.code-inline, code {
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a;
  display: inline;
  max-width: 100%;
  word-wrap: break-word;
  padding: 0.125rem 0.3125rem 0.0625rem;
}

.code-block {
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a;
  display: block;
  overflow: auto;
  white-space: pre;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }

  .medium-text-right {
    text-align: right;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-justify {
    text-align: justify;
  }
}
@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }

  .large-text-right {
    text-align: right;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-justify {
    text-align: justify;
  }
}
.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    color: black !important;
    color-adjust: economy;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .show-for-print {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print {
    display: table-cell !important;
  }

  th.show-for-print {
    display: table-cell !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  .ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre,
blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  .print-break-inside {
    page-break-inside: auto;
  }
}
html,
body {
  height: 100%;
  background-color: #F7F7F7;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.login_brand-logo {
  margin: 10px 0 32px 0;
}

fieldset {
  padding: 20px;
  border: 1px solid #F7F7F7;
}
fieldset legend {
  font-size: x-large;
}

/*components*/
.mat-icon.mat-icon-inline {
  vertical-align: text-top;
}

.toolbar {
  border: 1px solid #bdc3c7;
}
.toolbar .toolbar-button {
  border-right: 1px solid #bdc3c7 !important;
  border-radius: 0;
  height: 100%;
}
.toolbar .toolbar-button:first-child {
  border-right: none !important;
}
.toolbar .toolbar-button:last-child {
  border-left: 1px solid #bdc3c7 !important;
  border-right: none !important;
}
.toolbar .toolbar-button:nth-child(even) {
  border-left: 1px solid #bdc3c7 !important;
}

.menu_link {
  color: #F7F7F7;
  font-weight: 300;
}
.menu_link:hover {
  color: #fff;
}
.menu_link:visited {
  color: #fff;
}

.menu_link-active {
  color: #fff;
}

.menu_link_icon {
  position: relative;
  top: 6px;
}

.menu_expandable_panel {
  background: transparent;
  box-shadow: none !important;
}

.menu_expandable_panel_header {
  padding-left: 8px !important;
}
.menu_expandable_panel_header .mat-expansion-indicator::after {
  color: #fff;
  position: relative;
  top: -4px;
}

.menu_expandable_panel_header_title {
  color: #fff;
  margin-left: 7px;
}

.menu_expandable_panel_header_title_icon {
  margin-right: 10px;
  margin-left: 2px;
}

.profile-image {
  width: 36px;
  height: 36px;
  display: inline-block;
}

.profile-image--medium {
  width: 180px;
  height: 180px;
}

.header_profile-image-container {
  position: relative;
  min-height: 36px;
  padding-left: 6px;
  margin-left: 10px;
}

.header_profile-image-container_image {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  top: -8px;
}

.header_profile-image-container_image--size24 {
  width: 24px;
  height: 24px;
}

.header_profile-image-container_image--size16 {
  width: 16px;
  height: 16px;
}

.remove--p-top {
  top: 0;
}

.dataTable {
  border: 1px solid #bdc3c7;
  border-bottom: none;
  border-top: none;
  width: 100%;
}
.dataTable th {
  background-color: #656d78;
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 15px;
  text-transform: uppercase;
  text-align: left;
}
.dataTable th .mat-sort-header-arrow {
  color: #fff;
}
.dataTable th .mat-sort-header-button {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.dataTable td {
  color: #656d78;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.dataTable tr:hover {
  background: #bdc3c7;
  background: #ecf0f1;
}

.dataTable--sub th {
  background-color: #fff;
  color: #7f8c8d;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 15px;
  padding: 14px 0 14px 14px;
  border-bottom: 1px solid #bdc3c7;
  border-top: 1px solid #bdc3c7;
}
.dataTable--sub td {
  padding: 14px 0 14px 14px;
  color: #636663;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.dataTable--sub tr:hover {
  background: #bdc3c7;
}
.dataTable--sub tr::first {
  border-top: 1px solid #bdc3c7;
}

.dataTable-cell-item {
  margin-bottom: 4px;
}

.dataTable--sub-cell-item {
  font-weight: 100;
  font-family: "Roboto";
}

.editableDataTable {
  width: 100%;
}
.editableDataTable th {
  background-color: #656d78;
  color: #fff;
}

.editable-table-cell {
  background: #fff1d3;
  width: 100%;
  height: 100%;
}
.editable-table-cell:focus {
  outline: none;
}

.paginator {
  border-top: 1px solid #bdc3c7;
}

.stat {
  text-align: center;
}

.stat-main {
  font-size: 30px;
  font-weight: 200;
}

.stat-sub {
  font-size: 15px;
  font-weight: 400;
}

.section-header {
  margin: 10px 0 24px 0;
}

.section-header_button mat-icon {
  width: 30px;
  height: 30px;
  font-size: 30px;
}

.confirmation-dialog_icon {
  color: #bdc3c7;
  font-size: 80px;
  height: 80px;
  width: 80px;
  display: block;
}

.confirmation-dialog_message {
  color: #656d78;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.border--solid {
  border-style: solid;
}

.border--white {
  border-color: #fff;
}

.border--greenish-light {
  border-color: #BDC3C7;
}

.border--width-1 {
  border-width: 1px;
}

.border--width-2 {
  border-width: 2px;
}

.border--rounded {
  border-radius: 50%;
}

.border--rounded-5p {
  border-radius: 5%;
}

.mat-form-field {
  font-size: 14px;
  color: #656d78;
}

.mat-checkbox-layout .mat-checkbox-label {
  font-size: 14px;
  color: #656d78;
}

.full--width {
  width: 100%;
}

.full--height {
  height: 100vh;
}

.btn_progress-bar {
  height: 24px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 10px;
}

.mat-button.primary {
  border-radius: 2px;
  background: linear-gradient(270deg, #12e2ea 0%, #07bbc2 100%);
  color: #fff;
  min-width: 100px;
  min-height: 44px;
  text-transform: uppercase;
}

.mat-button.secondary {
  border: 1px solid #7f8c8d;
  border-radius: 2px;
  color: #7f8c8d;
  min-width: 100px;
  min-height: 44px;
  text-transform: uppercase;
}

.mat-button.danger {
  border: 1px solid #a6192e;
  border-radius: 2px;
  color: #a6192e;
  min-width: 100px;
  min-height: 44px;
}

.panel {
  background: #ffffff;
  padding: 20px 24px;
  overflow-y: auto;
}

h3 {
  color: #656d78;
  font-family: "Roboto";
  font-size: 28px;
  font-weight: bold;
}

h3 .mat-icon-button {
  vertical-align: top;
  line-height: initial;
}

h4 {
  color: #656d78;
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 200;
}

h5 {
  color: #7f8c8d;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
}

.border--red, .mat-button.danger--outlined {
  border-color: #fd3342 !important;
}

.border--orange {
  border-color: #ffae01 !important;
}

.border--green, .mat-button.success--outlined {
  border-color: #a8ca6b !important;
}

.border--grey {
  border-color: #656d78 !important;
}

.bg--gradiant-green, .lead--stepper .mat-step-header[aria-label=active] {
  background: linear-gradient(270deg, #2ac970 0%, #069545 100%);
}

.bg--greenish {
  background: #7F8C8D;
}

.bg--light-grey {
  background: #BDC3C7;
}

.auto-complete-list {
  font-size: 13px;
  vertical-align: middle;
  display: table-cell;
  vertical-align: middle;
}

.font--size-12 {
  font-size: 12px;
}

.font--size-16 {
  font-size: 16px;
}

.icon--small {
  height: 8px;
  width: 8px;
  font-size: 8px;
}

.icon--large {
  height: 128px;
  width: 128px;
  font-size: 128px;
}

.mat-icon-grey {
  color: #bdc3c7;
}

.heading-line {
  height: 2px;
  background: #07bbc2;
  background: linear-gradient(90deg, #07bbc2 25%, #ecf0f1 25%);
  border: 0;
  max-width: inherit;
}

.flat a:after {
  background: white;
  color: black;
}

.flat a:before {
  background: white;
}

.flat a:hover, .flat a.active, .flat a:hover:after, .flat a.active:after {
  background: #F7F7F7;
}

.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
  padding-left: 40px;
}

.avatar {
  margin-left: -10px;
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.avatar:hover {
  border: 1px solid #e7e7e7;
}

.avatar img {
  width: 40px;
  height: 40px;
}

/*modifiers*/
.spacer {
  flex: 1 1 auto;
}

.text--clickable {
  cursor: pointer;
}

.text--red, .mat-button.danger--outlined {
  color: #a6192e;
}

.text--green, .mat-button.success--outlined {
  color: #069545;
}

.text--light-grey {
  color: #bdc3c7;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--size-8 {
  font-size: 8px;
}

.text--size-12 {
  font-size: 12px;
}

.text--size-16 {
  font-size: 16px;
}

.text--size-20 {
  font-size: 20px;
}

.text--bold {
  font-weight: bold;
}

.text--greenish {
  color: #7F8C8D;
}

.text--white {
  color: #fff;
}

.text--wrap, .task-title, .task-description, .lead-box-title {
  white-space: normal;
}

.d-block {
  display: block;
}

.m--0 {
  margin: 0 !important;
}

.mx--30 {
  margin-left: 30px;
  margin-right: 30px;
}

.p--0 {
  padding: 0 !important;
}

.p--4 {
  padding: 4px !important;
}

.p--10 {
  padding: 10px;
}

.p--20 {
  padding: 20px;
}

.p--60 {
  padding: 60px;
}

.m-t-b--4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.m-t-b--10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-b--10 {
  margin-bottom: 10px !important;
}

.m-b--20 {
  margin-bottom: 20px;
}

.m-b--40 {
  margin-bottom: 40px;
}

.m-t-b--20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-t-b--30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-t--10 {
  margin-top: 10px;
}

.m-t--20 {
  margin-top: 20px;
}

.m-t--40 {
  margin-top: 40px;
}

.m-r--2 {
  margin-right: 2px;
}

.m-r--8 {
  margin-right: 8px;
}

.m-r--10 {
  margin-right: 10px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r--16 {
  margin-right: 16px;
}

.m-r--24 {
  margin-right: 24px;
}

.m-r--32 {
  margin-right: 32px;
}

.m-l--8 {
  margin-left: 8px;
}

.m-l--10 {
  margin-left: 10px;
}

.m-l--12 {
  margin-left: 12px;
}

.m-l--16 {
  margin-left: 16px;
}

.m-l--32 {
  margin-left: 32px;
}

.m-t--24 {
  margin-top: 24px;
}

.p-t--2 {
  padding-top: 2px;
}

.p-t--4 {
  padding-top: 4px;
}

.p-t--6 {
  padding-top: 6px;
}

.p-t--8 {
  padding-top: 8px;
}

.p-l--0 {
  padding-left: 0 !important;
}

.p-l--10 {
  padding-left: 10px;
}

.p-l--20 {
  padding-left: 20px;
}

.p-l--24 {
  padding-left: 24px;
}

.p-r--10 {
  padding-right: 10px;
}

.p-l-r--8 {
  padding-right: 8px;
  padding-left: 8px;
}

.p-l-r--10 {
  padding-right: 10px;
  padding-left: 10px;
}

.p-l-r--20 {
  padding-right: 20px;
  padding-left: 20px;
}

.p-l--11 {
  padding-left: 11px;
}

.p-r--12 {
  padding-right: 11px;
}

.p-l--12 {
  padding-left: 12px;
}

.p-r--12 {
  padding-right: 12px;
}

.p-l--16 {
  padding-left: 16px;
}

.p-r--16 {
  padding-right: 16px;
}

.p-t--0 {
  padding-top: 0;
}

.p-l--24 {
  padding-left: 24px;
}

.p-r--24 {
  padding-right: 24px;
}

.p-l--26 {
  padding-left: 26px;
}

.p-r--26 {
  padding-right: 26px;
}

.p-l--28 {
  padding-left: 28px;
}

.p-r--28 {
  padding-right: 28px;
}

.p-l-r--24 {
  padding-right: 24px;
  padding-left: 24px;
}

.p-l-r--18 {
  padding-right: 18px;
  padding-left: 18px;
}

.t-r--12 {
  position: relative;
  top: 12px;
}

.t-r--2 {
  position: relative;
  top: 2px;
}

.border-box {
  box-sizing: border-box;
}

.height--60 {
  height: 60px;
}

.border--red, .mat-button.danger--outlined {
  border-color: #a6192e;
}

.border--green, .mat-button.success--outlined {
  border-color: #069545;
}

.border--bottom {
  border-bottom: 1px solid #bdc3c7;
}

.editable-display {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px dashed #bdc3c7;
  display: block;
  margin-bottom: 8px;
}

.editable-display--large {
  color: #656d78;
  font-family: "Roboto";
  font-size: 28px;
}

/* Blue theme */
.mat-button.danger--outlined {
  background: #ffffff;
  border: 1px solid;
}

.mat-button.success--outlined {
  background: #ffffff;
  border: 1px solid;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #07bbc2;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #07bbc2;
}

.mat-badge-content {
  color: #fff;
  background: #07bbc2;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #07bbc2;
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #07bbc2;
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  background-color: #07bbc2;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: #07bbc2;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #07bbc2;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #07bbc2;
  color: #fff;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #7f8c8d;
  font-weight: 300;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #07bbc2;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #07bbc2;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #07bbc2;
}

.mat-icon.mat-primary {
  color: #07bbc2;
}

.mat-input-element {
  caret-color: #07bbc2;
}

.mat-progress-bar-fill::after {
  background-color: #07bbc2;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #07bbc2;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #07bbc2;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #07bbc2;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #07bbc2;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #07bbc2;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #07bbc2;
}

.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label,
.mat-primary .mat-slider-track-fill {
  background-color: #07bbc2;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #07bbc2;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #07bbc2;
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-header-pagination,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #07bbc2;
}

.mat-toolbar.mat-primary {
  background: #07bbc2;
  color: #fff;
}

.mat-drawer-container {
  color: #656d78;
}

.mat-tab-label {
  color: #7f8c8d;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
}

.mat-menu-item {
  padding: 0 24px 0 18px !important;
  text-transform: uppercase;
  line-height: 40px !important;
  height: 40px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #bdc3c7;
}

.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
  caret-color: #a6192e;
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #a6192e;
}