$progress-height: 15px;

.progress {
  height: progress-height;
  background: grey;
}

progress::-webkit-progress-bar {
  background: lightgrey;
}

progress.progress-green::-moz-progress-bar {
  height: progress-height;
  background: green;
}

progress.progress-green::-webkit-progress-value {
  height: progress-height;
  background: green;
}

progress.progress-red::-moz-progress-bar {
  height: progress-height;
  background: darkred;
}

progress.progress-red::-webkit-progress-value {
  height: progress-height;
  background: darkred;
}
