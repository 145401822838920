.modal-heading {
  border-bottom: 1px solid #ecf0f1;
  border-top: 4px solid #10a6ac;
  padding: 24px 0;
  margin-top: -24px;
  margin-bottom: 24px;
}

.mat-dialog-title {
  font-weight: 700;
  font-size: 24px;
  color: #656d78;
}

.modal-heading .mat-dialog-title {
  margin: 0;
}

.mat-dialog-content .mat-checkbox,
.mat-dialog-content mat-slide-toggle {
  display: block;
  margin: 12px 0;
}
