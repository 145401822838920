@import "variables";

.dx-mat-field {
    padding-bottom: 1.25em;

    label {
        font-size: 11px;
    }
}

.dx-button-mode-text.dx-button-default[aria-label="Save"] {
    background-color: $color-theme;
    color: #fff;

    .dx-state-hover {
        background-color: $color-theme;
    }
}

/*.dx-texteditor.dx-editor-underlined .dx-texteditor-input {
  padding-top: 5px;
}

.dx-texteditor-input,
.dx-placeholder {
  font-size: 14px;
}

.dx-placeholder {
  line-height: 0;
}*/

.dx-datagrid-headers {
    .dx-header-row {
        background-color: #656d78;
    }

    .dx-datagrid-table .dx-row > td {
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

    .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort,
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort-indicator,
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content {
        color: #e0e0e0;
    }

.dx-toolbar .dx-toolbar-items-container {
    height: 50px;
}

.dx-list-search {
    width: 50%;
}

.dx-list .dx-list-item,
.dx-list-item-content {
    white-space: normal;
}

.dx-popup-normal {
    border-top-color: $color-theme;
    border-top-style: solid;
    border-top-width: thick;
}

.dx-filemanager .dx-filemanager-toolbar {
    min-height: auto;
}

.dx-dashboard-control {
    background-color: initial;
}

.dx-dashboard-viewer-content {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.dx-dashboard-title-toolbar .dx-toolbar-item,
.dx-dashboard-caption-toolbar .dx-toolbar-item {
    color: #7f8c8d;
    font-family: "Roboto";
    font-size: 18px;
    font-weight: bold;
}

/*.dx-dashboard-item-container {
  border-radius: 0;
  box-shadow: none;

  @extend .border--solid, .border--width-1, .border--greenish-light
}
*/
